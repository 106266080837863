import { template as template_dac842e7e4f341fca24686d091577a58 } from "@ember/template-compiler";
const FKLabel = template_dac842e7e4f341fca24686d091577a58(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
