import { template as template_16644f4f1ab34700bb3b6b97435f21c8 } from "@ember/template-compiler";
const FKControlMenuContainer = template_16644f4f1ab34700bb3b6b97435f21c8(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
