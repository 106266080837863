import { template as template_d4cf59a23c6c4000aff31d380f4b6da8 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_d4cf59a23c6c4000aff31d380f4b6da8(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
